<section class="panel">
  <header class="panel__header pui-container">
    <h4 pui-typography="h2" i18n="@@addClientsPanelTitle">Add clients</h4>
    <pui-icon class="close-btn" svgIcon="pui:x" (click)="back()"></pui-icon>
  </header>

  <main class="panel__content">
    <form class="panel__form" [formGroup]="clientsForm">
      <ng-container formArrayName="{{ prefix }}">
        <fieldset
          class="panel__client-details"
          *ngFor="let clientFormGroup of clientsFormArray.controls; let formClientIndex = index; last as isLast"
          [formGroupName]="formClientIndex">
          <div class="pui-container">
            <div class="pui-row panel__row_align-right" *ngIf="canRemoveClient">
              <div class="pui-col">
                <button
                  pui-link
                  class="alert"
                  size="xs"
                  i18n="@@addClientPanelTitleRemove:Remove"
                  (click)="removeClientAt(formClientIndex)">
                  Remove
                </button>
              </div>
            </div>

            <div class="pui-row panel__row">
              <div class="pui-col_desktop-6 pui-col_mobile-12">
                <label pui-input-label class="required-field" [for]="'client_' + formClientIndex + '_first_name'">
                  <span i18n="@@addClientPanelFirstName:First name">First name</span>
                  <span class="alert">*</span>
                </label>
                <pui-input-field>
                  <input
                    formControlName="firstName"
                    pui-input-text
                    type="text"
                    [id]="'contact_' + formClientIndex + '_first_name'" />
                  <pui-input-error [formControl]="clientFormGroup.get('firstName')" role="alert"></pui-input-error>
                </pui-input-field>
              </div>

              <div class="pui-col_desktop-6 pui-col_mobile-12">
                <label pui-input-label class="required-field" [for]="'client_' + formClientIndex + '_last_name'">
                  <span i18n="@@addClientPanelLastName:Last name">Last name</span>
                  <span class="alert">*</span>
                </label>
                <pui-input-field>
                  <input
                    formControlName="lastName"
                    pui-input-text
                    type="text"
                    [id]="'client_' + formClientIndex + '_last_name'" />
                  <pui-input-error [formControl]="clientFormGroup.get('lastName')" role="alert"></pui-input-error>
                </pui-input-field>
              </div>
            </div>

            <div class="pui-row panel__row">
              <div class="pui-col_desktop-6 pui-col_mobile-12">
                <label pui-input-label class="required-field" [for]="'client_' + formClientIndex + '_email'">
                  <span i18n="@@addClientPanelEmail:Email">Email</span>
                  <span class="alert">*</span>
                </label>
                <pui-input-field>
                  <input
                    formControlName="email"
                    pui-input-text
                    type="text"
                    [id]="'client_' + formClientIndex + '_email'" />
                  <pui-input-error [formControl]="clientFormGroup.get('email')" role="alert"></pui-input-error>
                </pui-input-field>
              </div>

              <div class="pui-col_desktop-6 pui-col_mobile-12">
                <label
                  pui-input-label
                  i18n="@@addClientPanelPhone:Phone"
                  [for]="'client_' + formClientIndex + '_phone'">
                  Phone
                </label>
                <pui-input-field>
                  <input
                    formControlName="phone"
                    pui-input-text
                    type="tel"
                    inputmode="tel"
                    pui-mask="(000) 000-0000"
                    placeholder="(XXX) XXX–XXXX"
                    [id]="'client_' + formClientIndex + '_phone'" />
                  <pui-input-error [formControl]="clientFormGroup.get('phone')" role="alert"></pui-input-error>
                </pui-input-field>
              </div>
            </div>

            <div class="pui-row panel__row">
              <div class="pui-col">
                <label
                  class="label"
                  pui-input-label
                  i18n="@@addClientPanelOrganization:Organization"
                  [for]="'client_' + formClientIndex + '_organization'">
                  Organization
                </label>
                <pui-input-field>
                  <input
                    formControlName="organization"
                    pui-input-text
                    type="text"
                    [id]="'client_' + formClientIndex + '_organization'" />
                  <pui-input-error [formControl]="clientFormGroup.get('organization')" role="alert"></pui-input-error>
                </pui-input-field>
              </div>

              <div class="pui-col">
                <label
                  class="label"
                  pui-input-label
                  i18n="@@addClientPanelStage:Stage"
                  [for]="'client_' + formClientIndex + '_stage'">
                  Stage
                </label>
                <div class="field" *puiLet="getTag(clientFormGroup.get('tag').value) as tag">
                  <ng-container *ngIf="tag">
                    <pui-badge
                      [background]="tag.color"
                      class="field__badge stage-item__badge"
                      shape="square"></pui-badge>
                  </ng-container>
                  <pui-input-field>
                    <input
                      [class.field_fullfieled]="!!tag"
                      formControlName="tag"
                      pui-dropdown
                      [pui-dropdown-content]="stageDropdownContent"
                      [items]="tags"
                      placeholder="Choose a stage"
                      pui-input-text
                      readonly
                      type="text"
                      [id]="'client_' + formClientIndex + '_stage'" />
                  </pui-input-field>
                  <pui-icon svgIcon="pui:select" size="xs" class="field__select-icon"></pui-icon>
                </div>

                <ng-template #stageDropdownContent let-items="items" let-close="close">
                  <ul pui-list>
                    <li pui-list-item="button" *ngIf="clientFormGroup.get('tag').value" (click)="close(null)">
                      <span class="stage-item stage-item_empty">Choose a stage</span>
                    </li>
                    <li
                      pui-list-item="button"
                      *ngFor="let item of items | filter: excludeCurrentTag(clientFormGroup.get('tag').value)"
                      (click)="close(item.name)">
                      <span class="stage-item">
                        <pui-badge [background]="item.color" class="stage-item__badge" shape="square"></pui-badge>
                        {{ item.name }}
                      </span>
                    </li>
                  </ul>
                </ng-template>
              </div>
            </div>

            <div class="pui-row panel__row">
              <div class="pui-col">
                <label
                  pui-input-label
                  i18n="@@addClientPanelPrivateNotes:PrivateNotes"
                  [for]="'client_' + formClientIndex + '_notes'">
                  Private notes
                </label>
                <textarea
                  formControlName="notes"
                  class="form-control no-resize"
                  [id]="'contact_' + formClientIndex + '_notes'"
                  autosize
                  [minRows]="2"
                  [maxRows]="7"></textarea>
              </div>
            </div>
          </div>

          <pui-divider *ngIf="!isLast" class="panel__divider"></pui-divider>
        </fieldset>

        <div class="pui-container panel__row">
          <button
            pui-link
            size="xs"
            i18n="@@addClientPanelAddAnother:Add another"
            [disabled]="isDisabledAddClient$ | async"
            (click)="addAnotherClient()">
            Add another
          </button>
          &nbsp;
          <input type="file" class="d-none" #csv (change)="importCSV(csv.files)" accept=".csv,.txt" />
          <button
            pui-link
            size="xs"
            i18n="@@addClientPanelImportCSV:Import CSV"
            [ngbTooltip]="csvExampleTooltip"
            placement="top"
            [disabled]="isDisabledAddClient$ | async"
            (click)="csv.click()">
            Import CSV
          </button>
          <span style="float: right">
            <span class="alert">*</span>
            Required fields
          </span>
        </div>
      </ng-container>
    </form>
  </main>

  <footer class="pui-container panel__actions">
    <label i18n="@@addClientPanelEmailClients:Email clients" [formControl]="sendInvitationControl" pui-input-checkbox>
      Email clients an invitation to create an account
    </label>
    <button
      pui-button
      i18n="@@addClientPanelAddClient:Add clients"
      [disabled]="clientsForm.invalid"
      (click)="!clientsForm.invalid && saveClients()">
      Add clients
    </button>
  </footer>
</section>

<ng-template #csvExampleTooltip>
  <p i18n="@@CSVStructureExample">CSV structure example:</p>
  <p i18n="@@CSVFieldsNames">Email, FirstName, LastName, Phone, Organization, Private Notes</p>
  <p i18n="@@CSVFieldsValueExamples">email@example.com, John, Doe, +1 xxx xxx xxxx, Company LLC, note text</p>
</ng-template>
