import { GuideServices } from '@app/modules/service-scheduling/types';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { createAction, props } from '@ngrx/store';

import { SERVICE_SCHEDULING_STORE } from './commons/consts';
import { ServiceSchedulingStep } from './commons/types';

export const fetchAllServices = createAction(`[${SERVICE_SCHEDULING_STORE}] Fetch all services`);

export const fetchAllServicesSuccess = createAction(
  `[${SERVICE_SCHEDULING_STORE}] Fetch all services success`,
  props<{ services: GuideServices.RootObject[]; clientsIds: number[] }>()
);

export const fetchAllServicesError = createAction(`[${SERVICE_SCHEDULING_STORE}] Fetch all error`);

export const setClients = createAction(
  `[${SERVICE_SCHEDULING_STORE}] Set all clients`,
  props<{ clients: CRMClient[]; chosenClientsIds?: number[] }>()
);

export const setChosenClientsIds = createAction(
  `[${SERVICE_SCHEDULING_STORE}] Set chosen clients ids`,
  props<{ chosenClientsIds: number[] }>()
);

export const setSeriviceSchedulingStep = createAction(
  `[${SERVICE_SCHEDULING_STORE}] Set serivice scheduling step`,
  props<{ currentStep: ServiceSchedulingStep }>()
);

export const resetStepHistory = createAction(`[${SERVICE_SCHEDULING_STORE}] Reset steps history`);
