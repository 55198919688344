import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@awarenow/profi-ui-core';

import { ScheduleSessionPrimaryFilters } from './service-scheduling.service';

@Injectable()
export class ServiceSchedulingLSService {
  private SCHEDULING_CHOSEN_CLIENTS_IDS = `SCHEDULING_CHOSEN_CLIENTS_IDS`;
  private SERVICE_SCHEDULING_FILTERS = `SERVICE_SCHEDULING_FILTERS`;

  constructor(@Inject(WINDOW) private readonly window: Window) {}

  setChosenClientsIds(ids: number[]): void {
    this.window.localStorage.setItem(this.SCHEDULING_CHOSEN_CLIENTS_IDS, JSON.stringify(ids));
  }

  getChosenClientsIds(): number[] {
    const chosenClients = this.window?.localStorage?.getItem(this.SCHEDULING_CHOSEN_CLIENTS_IDS);
    return chosenClients ? JSON.parse(chosenClients) : [];
  }

  setServiceSchedulingFilters(filters: ScheduleSessionPrimaryFilters): void {
    this.window.localStorage.setItem(this.SERVICE_SCHEDULING_FILTERS, JSON.stringify(filters));
  }
  getSeriviceSchedulingFilters(): ScheduleSessionPrimaryFilters {
    const filters = this.window?.localStorage?.getItem(this.SERVICE_SCHEDULING_FILTERS);
    return filters ? JSON.parse(filters) : [];
  }
}
