<h4 class="title" transloco="createYourClientAccount" *ngIf="onlyClient">Create your client account</h4>
<h4 class="title" transloco="createYourGuideAccount" *ngIf="onlyGuide">Create your practitioner account</h4>

<div class="auth-form">
  <form [formGroup]="signUpForm" novalidate (ngSubmit)="signUp()">
    <div class="form-group">
      <label for="email" i18n="@@email">Email</label>
      <input
        formControlName="email"
        id="email"
        type="email"
        class="form-control"
        readonly
        data-qa-id="sign-up-textfield-email" />
    </div>

    <div class="form-group">
      <label i18n="@@password">Password</label>
      <app-input-password
        componentId="password"
        formControlName="password"
        className="lg"
        qaId="sign-up-textfield-password"></app-input-password>
      <app-form-control-error [control]="signUpForm.get('password')" *ngIf="passwordError as error">
        <ng-container [ngSwitch]="error">
          <ng-container *ngSwitchCase="'required'" i18n="@@passwordRequired">Password required.</ng-container>

          <ng-container *ngSwitchCase="'minlength'" i18n="@@passwordTooShort">
            Password must be at least 6 chars long.
          </ng-container>

          <ng-container *ngSwitchCase="'empty'" i18n="@@passwordIsEmpty">
            Password shouldn't contain only whitespaces.
          </ng-container>
        </ng-container>
      </app-form-control-error>
    </div>

    <div class="form-group">
      <label for="fName" i18n="@@firstName">First name</label>
      <input
        formControlName="firstName"
        id="fName"
        (change)="trimValue(signUpForm.controls['firstName'])"
        type="text"
        class="form-control"
        data-qa-id="sign-up-textfield-firstname" />
      <app-form-control-error [control]="signUpForm.controls['firstName']" i18n="@@firstNameRequiredOnlyLetters">
        Please, use only letters. First name required
      </app-form-control-error>
    </div>

    <div class="form-group">
      <label for="lName" i18n="@@signUpLastName">Last name</label>
      <input
        formControlName="lastName"
        id="lName"
        (change)="trimValue(signUpForm.controls['lastName'])"
        type="text"
        class="form-control"
        data-qa-id="sign-up-textfield-lastname" />
      <app-form-control-error [control]="signUpForm.controls['lastName']" i18n="@@lastNameRequiredOnlyLetters">
        Please, use only letters. Last name required
      </app-form-control-error>
    </div>

    <ng-container *ngIf="!onlyClient && !onlyGuide">
      <div class="form-group">
        <label for="isGuide" i18n="@@signUpAsA">Sign up as a</label>
        <app-checkbox
          id="isGuide"
          formControlName="isGuide"
          class="auth-checkbox sign-up-as"
          data-qa-id="sign-up-practitioner-checkbox">
          <ng-container transloco="practitioner">Practitioner</ng-container>
        </app-checkbox>
        <app-checkbox
          id="isClient"
          formControlName="isClient"
          class="auth-checkbox sign-up-as"
          [disabled]="isClientDisabled">
          <ng-container i18n="@@client">Client</ng-container>
        </app-checkbox>
        <app-form-control-error
          [show]="
            signUpForm.errors &&
            signUpForm.errors.roleError &&
            ((signUpForm.controls['isGuide'].dirty && signUpForm.controls['isGuide'].touched) ||
              (signUpForm.controls['isClient'].dirty && signUpForm.controls['isClient'].touched))
          "
          i18n="@@invalidRole">
          Choose at least one role.
        </app-form-control-error>
      </div>

      <p
        *ngIf="signUpForm.controls['isClient'].value && signUpForm.controls['isGuide'].value"
        class="info-text"
        transloco="bothRolesInfoText">
        Great! We’ll set up your practitioner dashboard first.
      </p>
    </ng-container>

    <div class="form-group checkboxes">
      <app-checkbox
        *ngIf="config.showNewsletter"
        id="agreeEmail"
        formControlName="subscribeNews"
        class="auth-checkbox"
        i18n="@@agreeToReceiveEmails">
        I agree to receive {{ platformName }} email newsletter
      </app-checkbox>
      <app-checkbox
        id="agreeWithTerms"
        formControlName="agreeWithTerms"
        class="auth-checkbox required"
        i18n="@@signUpConfirmTermsAndPrivacyPolicy"
        data-qa-id="sign-up__checkbox-agreements">
        I authorize the processing of personal data and agree with
        <a href="{{ config.urlTerms }}" target="_blank" rel="noopener">Terms&Conditions</a>
        and
        <a href="{{ config.urlPrivacy }}" target="_blank" rel="noopener">Privacy Policy</a>.
      </app-checkbox>
      <app-form-control-error
        [control]="signUpForm.controls['agreeWithTerms']"
        i18n="@@signUpMustAcceptPolicies"
        class="policies-error">
        You must accept the policies
      </app-form-control-error>
    </div>

    <div class="auth-buttons centered">
      <app-button
        className="btn-primary btn-lg"
        buttonType="submit"
        i18n="@@createAccount"
        data-qa-id="sign-up__button-submit">
        Create account
      </app-button>
    </div>

    <div class="pui-space_top-1 text-center">
      <small pui-typography="body-s">
        This site is protected by reCAPTCHA and the Google
        <a target="_blank" rel="noopener" href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a target="_blank" rel="noopener" href="https://policies.google.com/terms">Terms of Service</a> apply.
      </small>
    </div>
  </form>
</div>
