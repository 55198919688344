import { DateTime } from 'luxon';

import { Injectable } from '@angular/core';
import { ServiceSchedulingService } from '@app/modules/service-scheduling/services/service-scheduling.service';
import {
  Contacts,
  CreateGuideClient,
  CRMClient
} from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import {
  createClients,
  setClients
} from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.actions';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class GuideClientsFeaturesService {
  constructor(private store$: Store, private serviceSchedulingService: ServiceSchedulingService) {}

  createClients(data: CreateGuideClient): void {
    this.store$.dispatch(createClients({ create: data }));
  }

  setOrganization(id: CRMClient['id'], organization: Contacts['organization']): void {
    if (id === undefined) {
      console.warn('Parameter {clientId} is required!');

      return;
    }

    this.store$.dispatch(
      setClients({
        payload: {
          [id]: { organization }
        }
      })
    );
  }

  setPhoneNumber(id: CRMClient['id'], phone: Contacts['phone']): void {
    if (id === undefined) {
      console.warn('Parameter {clientId} is required!');

      return;
    }

    this.store$.dispatch(
      setClients({
        payload: {
          [id]: { phone }
        }
      })
    );
  }

  setFirstName(id: CRMClient['id'], firstName: Contacts['firstName']): void {
    if (id === undefined) {
      console.warn('Parameter {clientId} is required!');

      return;
    }

    this.store$.dispatch(
      setClients({
        payload: {
          [id]: { firstName }
        }
      })
    );
  }

  setLastName(id: CRMClient['id'], lastName: Contacts['lastName']): void {
    if (id === undefined) {
      console.warn('Parameter {clientId} is required!');

      return;
    }

    this.store$.dispatch(
      setClients({
        payload: {
          [id]: { lastName }
        }
      })
    );
  }

  setEmail(id: CRMClient['id'], email: Contacts['email']): void {
    if (id === undefined) {
      console.warn('Parameter {clientId} is required!');

      return;
    }

    this.store$.dispatch(
      setClients({
        payload: {
          [id]: { email }
        }
      })
    );
  }

  archiveClient(id: CRMClient['id']): void {
    if (id === undefined) {
      console.warn('Parameter {id} is required!');

      return;
    }

    this.store$.dispatch(
      setClients({
        payload: {
          [id]: {
            archived: true,
            archivedAt: DateTime.local().toISO()
          }
        }
      })
    );
  }

  archiveClients(ids: CRMClient['id'][]): void {
    this.store$.dispatch(
      setClients({
        payload: ids.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              archived: true,
              archivedAt: DateTime.local().toISO()
            }
          }),
          {}
        )
      })
    );
  }

  unArchiveClient(id: CRMClient['id']): void {
    if (id === undefined) {
      console.warn('Parameter {clientId} is required!');

      return;
    }

    this.store$.dispatch(
      setClients({
        payload: { [id]: { archived: false, archivedAt: null } }
      })
    );
  }

  unArchiveClients(ids: CRMClient['id'][]): void {
    this.store$.dispatch(
      setClients({
        payload: ids.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              archived: false,
              archivedAt: null
            }
          }),
          {}
        )
      })
    );
  }

  stageClient(id: CRMClient['id'], stage: string): void {
    if (id === undefined) {
      console.warn('Parameter {id} is required!');

      return;
    }

    this.store$.dispatch(
      setClients({
        payload: {
          [id]: { tag: stage }
        }
      })
    );
  }

  tagClient(id: CRMClient['id'], tags: string[]): void {
    if (id === undefined) {
      console.warn('Parameter {clientId} is required!');

      return;
    }

    this.store$.dispatch(
      setClients({
        payload: {
          [id]: {
            customFields: {
              client_tags: Array.from(tags)
            }
          }
        }
      })
    );
  }

  proposeAService(clients: CRMClient[]): void {
    this.serviceSchedulingService.proposeService({
      clientsIds: clients.map(client => client.relationId)
    });
  }
}
