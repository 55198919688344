import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { PlatformConfigurationService } from '@app/core/platform-configuration';
import { ISwitchToAlternativeAccountView } from '@app/modules/sidenav/components/sidenav-switch-to-alternative-account/switch-to-alternative-account/switch-to-alternative-account.component';
import { SidenavModeService } from '@app/modules/sidenav/services';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GlobalConfig } from '@cnf/types';
import { TranslocoService } from '@ngneat/transloco';

type AlternativeAccountServiceBrandingConfig = Pick<GlobalConfig, 'hideSignInAs' | 'hideSignUpAs'>;

@Injectable()
export class SwitchToAlternativeAccountService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _open$: Subject<boolean> = new Subject<boolean>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _view$: Observable<ISwitchToAlternativeAccountView>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _config$: Observable<AlternativeAccountServiceBrandingConfig> =
    this._configurationService.switchConfigurations$;

  readonly PRACTITIONER_LABEL = this.translocoService.translate(`practitioner`);
  readonly CLIENT_LABEL = this.translocoService.translate(`client`);

  constructor(
    private readonly _configurationService: PlatformConfigurationService,
    private readonly _authSrv: AuthService,
    private readonly _mode$: SidenavModeService,
    private translocoService: TranslocoService
  ) {
    this._view$ = combineLatest([this._config$, _authSrv.onAuth(), _mode$, this._open$.pipe(startWith(false))]).pipe(
      map(([config, auth, mode, open]) => {
        const acceptableRoles: UserRoles[] = [UserRoles.CLIENT, UserRoles.GUIDE];

        return {
          mode,
          open,
          labels: [this.PRACTITIONER_LABEL, this.CLIENT_LABEL],
          labelIndex: auth?.RoleId === UserRoles.GUIDE ? 0 : 1,
          visible: !config.hideSignInAs && acceptableRoles.includes(auth?.RoleId) && auth?.hasAlternativeProfile
        };
      })
    );
  }

  getView$(): Observable<ISwitchToAlternativeAccountView> {
    return this._view$;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  switchToAlternativeAccount() {
    return this._authSrv.signinAlternativeAccount();
  }

  setOpen(open: boolean): void {
    this._open$.next(open);
  }
}
