import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-guide-service-badge',
  template: '{{ text }}',
  styleUrls: ['guide-service-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideServiceBadgeComponent {
  @HostBinding('class') hostClass = 'guide-service-badge';

  @Input()
  text = '';
}
