<div class="modal-header">
  <h4 class="modal-title" i18n="@@addClient">Add client</h4>

  <app-button class="close-btn" className="close" (click)="modal.dismiss()"></app-button>
</div>

<div class="modal-body">
  <form [formGroup]="contactsForm" class="form new-contact-form">
    <ng-container formArrayName="contacts">
      <fieldset
        class="contact-details"
        *ngFor="let contactFormGroup of contactsFormArray.controls; let formContactIndex = index"
        [formGroupName]="formContactIndex">
        <div class="form-row remove-contact-row" *ngIf="canRemoveContact">
          <div class="col-12">
            <app-button className="btn-link" (click)="removeContactAt(formContactIndex)" i18n="@@remove">
              Remove
            </app-button>
          </div>
        </div>

        <div class="form-row">
          <div class="col-12 col-sm-6">
            <label class="label" [for]="'contact_' + formContactIndex + '_first_name'" i18n="@@firstName">
              First name
            </label>
            <input
              type="text"
              formControlName="firstName"
              class="form-control"
              [id]="'contact_' + formContactIndex + '_first_name'" />
            <app-form-control-error
              [control]="contactFormGroup.get('firstName')"
              *ngIf="contactFormGroup.get('firstName').errors && contactFormGroup.get('firstName').errors.required"
              i18n="@@firstNameRequired">
              First name is required.
            </app-form-control-error>
            <app-form-control-error
              [control]="contactFormGroup.get('firstName')"
              *ngIf="contactFormGroup.get('firstName').errors && contactFormGroup.get('firstName').errors.maxlength"
              i18n="@@maxLength">
              Max length is
              {{ contactFormGroup.get('firstName').errors.maxlength.requiredLength }} characters.
            </app-form-control-error>
          </div>

          <div class="col-12 col-sm-6">
            <label class="label" [for]="'contact_' + formContactIndex + '_last_name'" i18n="@@addContactModalLastName">
              Last name
            </label>
            <input
              type="text"
              formControlName="lastName"
              class="form-control"
              [id]="'contact_' + formContactIndex + '_last_name'" />
            <app-form-control-error
              [control]="contactFormGroup.get('lastName')"
              *ngIf="contactFormGroup.get('lastName').errors && contactFormGroup.get('lastName').errors.required"
              i18n="@@lastNameRequired">
              Last name required.
            </app-form-control-error>
            <app-form-control-error
              [control]="contactFormGroup.get('lastName')"
              *ngIf="contactFormGroup.get('lastName').errors && contactFormGroup.get('lastName').errors.maxlength"
              i18n="@@maxLength">
              Max length is
              {{ contactFormGroup.get('lastName').errors.maxlength.requiredLength }} characters.
            </app-form-control-error>
          </div>
        </div>

        <div class="form-row">
          <div class="col-12 col-sm-12">
            <label class="label" [for]="'contact_' + formContactIndex + '_organization'" i18n="@@organization">
              Organization
            </label>
            <input
              type="text"
              formControlName="organization"
              class="form-control"
              [id]="'contact_' + formContactIndex + '_organization'" />
            <app-form-control-error
              [control]="contactFormGroup.get('organization')"
              *ngIf="
                contactFormGroup.get('organization').errors && contactFormGroup.get('organization').errors.maxlength
              "
              i18n="@@maxLength">
              Max length is
              {{ contactFormGroup.get('organization').errors.maxlength.requiredLength }} characters.
            </app-form-control-error>
          </div>
        </div>

        <div class="form-row">
          <div class="col-12 col-sm-6">
            <label class="label" [for]="'contact_' + formContactIndex + '_email'" i18n="@@email">Email</label>
            <input
              type="email"
              formControlName="email"
              class="form-control"
              [id]="'contact_' + formContactIndex + '_email'" />
            <app-form-control-error
              [control]="contactFormGroup.get('email')"
              *ngIf="contactFormGroup.get('email').errors"
              i18n="@@correctEmailRequired">
              Correct email is required.
            </app-form-control-error>
            <app-form-control-error
              [control]="contactFormGroup.get('email')"
              *ngIf="contactFormGroup.get('email').errors && contactFormGroup.get('email').errors.maxlength"
              i18n="@@maxLength">
              Max length is
              {{ contactFormGroup.get('email').errors.maxlength.requiredLength }} characters.
            </app-form-control-error>
          </div>

          <div class="col-12 col-sm-6">
            <label class="label" [for]="'contact_' + formContactIndex + '_phone'" i18n="@@phone">Phone</label>
            <pui-phone-number
              formControlName="phone"
              [id]="'contact_' + formContactIndex + '_phone'"></pui-phone-number>
            <app-form-control-error
              [control]="contactFormGroup.get('phone')"
              *ngIf="contactFormGroup.get('phone').errors && contactFormGroup.get('phone').errors.invalidNumber"
              i18n="@@phoneRequired">
              Correct phone number is required.
            </app-form-control-error>
          </div>
        </div>

        <div class="form-row">
          <div class="col-12">
            <label class="label" [for]="'contact_' + formContactIndex + '_notes'" i18n="@@privateNotes">
              Private notes
            </label>
            <textarea
              formControlName="notes"
              class="form-control no-resize fs-exclude"
              [id]="'contact_' + formContactIndex + '_notes'"
              autosize
              [minRows]="2"
              [maxRows]="7"></textarea>
          </div>
        </div>
      </fieldset>
    </ng-container>

    <div class="form-row create-contacts-row">
      <div class="col-12">
        <app-button
          class="add-contact-btn-container"
          className="btn-link"
          (click)="addAnotherContact()"
          [disabled]="isDisabledAddClient"
          i18n="@@addAnother">
          Add Another
        </app-button>

        <span [ngbTooltip]="csvExampleTooltip" tooltipClass="money-back-tooltip" placement="top">
          <input type="file" class="d-none" #csv (change)="importCSV(csv.files)" accept=".csv,.txt" />
          <button
            (click)="csv.click()"
            type="button"
            class="btn btn-link add-btn"
            i18n="@@importCSV"
            [disabled]="isDisabledAddClient">
            Import CSV
          </button>
        </span>
      </div>
    </div>

    <div class="form-row">
      <div class="col-12">
        <app-button className="btn-primary" (click)="saveContacts()" i18n="@@addClient">Add client</app-button>
      </div>
    </div>
  </form>
</div>

<ng-template #csvExampleTooltip>
  <p i18n="@@CSVStructureExample">CSV structure example:</p>
  <p i18n="@@CSVFields">Email, FirstName, LastName, Phone</p>
  <p i18n="@@CSVFieldsValues">email@example.com, John, Doe, +1 xxx xxx xxxx</p>
</ng-template>
